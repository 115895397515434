import { Injectable } from '@angular/core';
import { coinDogBasePrice } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  // socket: WebSocket;
  // public coinDogPrice = coinDogBasePrice.value;
  // public CoinDogBaseCurrency = coinDogBasePrice.currency;
  constructor() {
    // const sends = () => {
    //   const data = {
    //     op: "unconfirmed_sub"
    //   }
    //   this.socket.send(JSON.stringify(data))


    // }
    // this.socket = new WebSocket('wss://ws.blockchain.info/inv')
    // this.socket.onopen = function () {

    //   console.log("Соединение установлено.");
    //   sends()
    // };
    // this.socket.onmessage = function (event) {
    //   console.log("Получены данные " + event.data);


    // };

    // this.socket.onerror = function (error: any) {
    //   alert("Ошибка " + error.message);
    // };
    // this.socket.onclose = function (event) {
    //   if (event.wasClean) {
    //     alert('Соединение закрыто чисто');
    //   } else {
    //     alert('Обрыв соединения'); // например, "убит" процесс сервера
    //   }
    //   alert('Код: ' + event.code + ' причина: ' + event.reason);
    // };
  }

  public getCoinDogConfig = () => {
    return coinDogBasePrice;
  }
}
