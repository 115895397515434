import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-bounty-hunt',
  templateUrl: './bounty-hunt.component.html',
  styleUrls: ['./bounty-hunt.component.scss']
})
export class BountyHuntComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(5)]),
  });
  constructor() { }

  ngOnInit() {
    this.form.valueChanges.subscribe(res => {
      // console.log(res);

    });
    this.form.statusChanges.subscribe(res => {
      // console.log(this.form);

    });
  }
  test(e) {
    console.log('HI');
    console.log(e);

  }
}
