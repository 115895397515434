import { Component, OnInit } from '@angular/core';

import { usedCurrencies } from '../../../config';

@Component({
  selector: 'app-card-balance',
  templateUrl: './card-balance.component.html',
  styleUrls: ['./card-balance.component.scss']
})
export class CardBalanceComponent implements OnInit {
  currencies: any[] = usedCurrencies.map(i => {
    return `assets/img/new/crypto_logo/${i}.svg`;
  });
  constructor() { }

  ngOnInit() {
    console.log(this.currencies);
    
  }

}
