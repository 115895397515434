import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface ViewOptions {
  sideNav: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ViewOptionService {
  viewOptions: ViewOptions = {
    sideNav: false
  };
  public options = new ReplaySubject<ViewOptions>();
  constructor() {
    this.options.next(this.viewOptions);
  }
  public toggleSideNav = () => {
    this.viewOptions = { ...this.viewOptions, sideNav: !this.viewOptions.sideNav };
    this.options.next(this.viewOptions);
  }
}
