import { Component, OnInit, forwardRef, Host, Optional, ElementRef, Attribute, SkipSelf, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ControlContainer, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputTextComponent),
    }
  ],

})
export class InputTextComponent implements ControlValueAccessor, OnInit {
  @ViewChild('input')
  input: ElementRef;
  errors: { [key: string]: string };
  disabled: boolean;
  value: string;
  placeholder: string;
  formControlName: string;
  control: AbstractControl;
  transformPlaceholder: boolean;
  focus: boolean;
  showError: boolean;

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    @Attribute('formControlName') controlName,
    @Attribute('placeholder') placeholder,
    private el: ElementRef
  ) {
    this.formControlName = controlName;
    this.placeholder = placeholder;

  }

  onChange: (_: any) => void = (_: any) => { };


  onTouched: () => void = () => { };
  deligerToInput = (e: Event) => {
    // console.log('HERE');
    // // const input =
    // console.log(this.input);
    // this.transformPlaceholder = true;
    // this.input.nativeElement.click();
    this.input.nativeElement.focus();

  }
  checkErrors = () => {
    console.log(this.control.errors);

    if (!this.control.value) {
      this.transformPlaceholder = false;
    }
    if (this.control.errors) {
      this.errors = this.control.errors;
      this.showError = !!this.errors;
    }
    if (!this.errors) {
      this.showError = false;
    }

  }
  updateChanges() {
    this.onChange(this.value);
    this.errors = this.control.errors;
    if (this.control.valid) {
      this.showError = false;
    }



    if (!!this.control.value) {
      this.transformPlaceholder = true;
    }
    if (this.transformPlaceholder && !this.control.value && !this.focus) {
      this.transformPlaceholder = false;
    }
  }
  changeTranformPlaceholder = (value: boolean) => {
    this.transformPlaceholder = value;
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateChanges();

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }


  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  getError = () => {
    return 'error';
  }
  ngOnInit() {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }

  }

}
