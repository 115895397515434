import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

import { dropDownAnimation } from '@animations';

type Direction = 'fromLeft' | 'fromRight';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    dropDownAnimation]
})
export class DropdownComponent implements OnInit {
  @Input()
  direction?: Direction;
  @Input()
  absolute?: boolean;
  open = false;
  constructor() { }
  toggle() {
    console.log(this.open);
    this.open = !this.open;

  }
  ngOnInit() {
    // if(this.absolute) {

    // }
    // console.log(this.template);
console.log(this.absolute);

  }

}
// trigger('routeAnimations', [
//   transition('* <=> *', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//           style({
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               width: '100%'
//           })
//       ], { optional: true }),
//       query(':enter', [
//           style({ left: '-100%' })
//       ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//           query(':leave', [
//               animate('300ms ease-out', style({ left: '100%' }))
//           ], { optional: true }),
//           query(':enter', [
//               animate('300ms ease-out', style({ left: '0%' }))
//           ], { optional: true })
//       ]),
//       query(':enter', animateChild(), { optional: true }),
//   ])]);
