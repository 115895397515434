import { Currency } from '../currency.service';

import { usedCurrencies, currencyNames } from '../../config';

export const getUsedCurrencyPairs = (available: Currency): string[] => {
    const arr = [];
    const keys = Object.keys(available);
    usedCurrencies.forEach(curr => {
        keys.forEach(key => {
            if (key === `ETH_${curr}`) {
                arr.push(key);
            }

        });
        keys.forEach(key => {
            if (key === `BTC_${curr}`) {
                // console.log(arr);

                if (!arr.includes(`ETH_${curr}`)) {
                    arr.push(key);
                }
            }
        });
    });
    return arr;

};
// export const assignCurrencyNames = (currencies: Currency) => {
//     let a;
//     // tslint:disable-next-line:forin
//     for (const key in currencies) {
//         a[key] = { ...currencies[key], name: currencyNames[key] };
//     }
//     return a;
// };

