import { Component, OnInit } from '@angular/core';

import { TransactionService } from '@services';

import { QrCodeService, Payment } from '../../services/qr-code.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss']
})
export class PaymentDetailComponent implements OnInit {
  payment: Payment;
  qrImg: string;
  constructor(
    private router: Router,
    private qrImgService: QrCodeService,
    private transactionService: TransactionService,
  ) {
    qrImgService.paymentDetails.subscribe(res => {
      this.payment = res;
    });
  }
  sendTransaction = (e: Event) => {
    e.preventDefault();
    this.transactionService.sendTransaction(this.payment);
    this.router.navigateByUrl('profile/transactions');
  }
  ngOnInit() {
    if (!this.payment) {
      this.router.navigateByUrl('deposit');
    }
  }
}
