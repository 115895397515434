import { trigger, transition, style, animateChild, animate, group, query } from '@angular/animations';

export const opacityAnimation =
    trigger('opacity', [
        transition(':enter', [
            style({
                opacity: 0
            }),
            animate('0.5s linear', style({
            }))
        ]),
        transition(':leave', [
            style({
                opacity: 1,
            }),
            animate('0.5s linear', style({
                opacity: 0,
            }))
        ])
    ]);
