import { Component, OnInit } from '@angular/core';
import { DeviceService, Device } from '@services';

import { dropDownAnimation } from '@animations';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [dropDownAnimation]
})
export class FAQComponent implements OnInit {
  device: Device;
  faq =
    {
      title: 'Duis euismod molestie mi',
      // tslint:disable-next-line:max-line-length
      text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem. Nunc ac urna eget libero fringilla pretium. Donec sit amet mauris enim. Vestibulum dictum, mi lobortis accumsan condimentum, massa diam bibendum libero, id placerat velit ligula sit amet'
    };

  faqList = new Array(10).fill(this.faq).map((item, index) => {
    return { ...item, id: index };
  });
  selected = this.faqList[0];
  constructor(
    private deviceService: DeviceService
  ) {
    deviceService.device.subscribe(res => {
      this.device = res;
    });

  }
  toggleFaqItem(faqItem) {
    if (this.selected && faqItem.id === this.selected.id) {
      this.selected = null;
    } else {
      this.selected = faqItem;
    }
    console.log('BEFORE', this.selected);
    // console.log(faqItem);

    console.log('AFTER', this.selected);
  }
  ngOnInit() {


  }

}
