import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfigService, CurrencyService } from '@services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-token-calculator',
  templateUrl: './card-token-calculator.component.html',
  styleUrls: ['./card-token-calculator.component.scss']
})
export class CardTokenCalculatorComponent implements OnInit {
  formInput = new FormControl();
  total: { value: number, currency: string } = { value: 0, currency: this.configService.getCoinDogConfig().currency };
  constructor(private configService: ConfigService, private currencyService: CurrencyService, private router: Router) { }
  navigateToPayment = (e: Event) => {
    if (!this.currencyService.getCoinDogAmount()) {
      e.preventDefault();
    } else {
      this.router.navigateByUrl('deposit');
    }
  }
  ngOnInit() {
    this.formInput.valueChanges.subscribe(res => {
      const value = this.configService.getCoinDogConfig().value * res;
      this.total = { ...this.total, value };
      this.currencyService.updateCoinDogAmount(res);

    });

  }

}
