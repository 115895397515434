import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Store, Section } from '../../../store';
import { DeviceService } from '../../../services/device.service';

import { ViewOptionService, ViewOptions } from '../../../services/view-option.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  device: string;
  text = new Array(10).fill('Just bought 3 ETH in Irland');
  viewOptions: ViewOptions;

  constructor(
    private store: Store,
    private deviceService: DeviceService,
    private viewOptionService: ViewOptionService

  ) { }
  ngOnInit() {
    this.viewOptionService.options.subscribe(res => {
      console.log(res);

      this.viewOptions = res;
    });
    this.deviceService.device.subscribe(res => {
      this.device = res;
    });
  }
  ngOnDestroy() { }
  toggleSideMenu() {
    // console.log('HI');
    this.viewOptionService.toggleSideNav();
    // this.open = !this.open;
    // this.store.set(Section.sideNavOpen, this.open);
  }
}
