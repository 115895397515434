import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-token-list',
  templateUrl: './card-token-list.component.html',
  styleUrls: ['./card-token-list.component.scss']
})
export class CardTokenListComponent implements OnInit {
  listItem = {
    token: true,
    amount: 4123.5234,
    value: '3.523 BTC',
    date: Date.now(),
    button: 'Purchade'
  };
  list = new Array(3).fill(this.listItem);
  constructor() { }

  ngOnInit() {
  }

}
