import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';

import { getUsedCurrencyPairs } from './helpers/currency.helper';

import { mainCurrency, usedCurrencies } from '../config';
type UsedCurrencies = 'BTC' | 'ETH' | 'LTC' | 'BCH' | 'DASH';
export interface Currency {
  [pairKey: string]: {
    id: number;
    last: string;
    lowestAsk: string;
    highestBid: string;
    percentChange: string;
    baseVolume: string;
    quoteVolume: string;
    isFrozen: string;
    high24hr: string;
    low24hr: string;
  };
}
// type cryptocompareApiResponseMainCurrency = {
//   [key in UsedCurrencies]: {
//     USD: number
//   };
// };
type Main = 'USD';
export type FromCryptoToUSD = {
  [key in UsedCurrencies]: {
    USD: number
  };
};
export type FromUSDtoCrypto = {
  [usd in Main]: {
    [key in UsedCurrencies]: number;
  }
};
// const usedCurrencies = [
//   'BTC',
//   'LTC',
//   'ETH',
//   'XMR',
//   'BCH',
//   'DOGE ',
//   'ZEC',
//   'DASH'
// ];

const url = 'https://poloniex.com/public?command=returnTicker';
@Injectable({
  providedIn: 'root'
})

export class CurrencyService {
  validPairs;
  test: Currency = {};

  public coinDogAmount: number;
  public tickerData = new ReplaySubject<Currency>();
  public fromCryptoToUSD = new ReplaySubject<FromCryptoToUSD>();
  public fromUSDtoCrypto = new ReplaySubject<FromUSDtoCrypto>();
  constructor(private http: HttpClient) {
    http.get(url).toPromise().then((res: Currency) => {
      this.validPairs = getUsedCurrencyPairs(res);
      this.validPairs.forEach(pair => {
        this.test[pair] = res[pair];
      });
      this.tickerData.next(this.test);
    });
    http.get(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=${usedCurrencies.join(',')},ETH&tsyms=USD`).subscribe(res => {
      this.fromCryptoToUSD.next(res as FromCryptoToUSD);
      // console.log(res);

    });

    http.get(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=USD&tsyms=${usedCurrencies.join(',')}`).toPromise().then(res => {

      this.fromUSDtoCrypto.next(res as FromUSDtoCrypto);
    });
    // setInterval(() => {
    //   http.get(url).toPromise().then((res: [any]) => {
    //     this.tickerData.next(this.assignSvgImg(res));
    //   });
    // }, 60000);
  }
  updateCoinDogAmount = (value: number) => {
    this.coinDogAmount = value;
  }
  getCoinDogAmount = () => {
    return this.coinDogAmount;
  }
  assignSvgImg = (arr: any[]) => {
    arr.slice(1, 30);
    return arr.map(item => {
      // if (notValid.includes(item.symbol)) {
      //   return null;
      // }
      item.currencyLogoImg = `assets/img/new/crypto_logo/${item.symbol}.svg`;
      return item;
    }).filter(item => item);

  }

}

