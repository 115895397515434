import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Payment } from './qr-code.service';
type Status = 'paid' | 'process' | 'timeout';
export type Transaction = Payment & {
  status: Status,
  date: number

};
const fake = {
  CDS: 226.68,
  date: Date.now(),
  id: 'ETH',
  // tslint:disable-next-line:max-line-length
  qrImg: 'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=ethereum:0x692aBE423A9e2966CC1719565a314B809bdA2ABf?amount=1&gas=undefined',
  status: 'process',
  value: 1,
  wallet: '0x692aBE423A9e2966CC1719565a314B809bdA2ABf',

}
@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  public transactions = new ReplaySubject<Transaction>();
  constructor() {
    this.transactions.next({ ...fake, status: 'timeout' });
    this.transactions.next({ ...fake, status: 'paid' });
    this.transactions.next({ ...fake, status: 'process' });
  }
  public sendTransaction = (transaction: Payment) => {
    this.transactions.next({ ...transaction, status: 'process', date: Date.now() });
  }
}
