import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularModule } from './modules/angular.module';
import { MatIconModule } from '@angular/material/icon';
// import {ProfileNavigationComponent} from './components/header/profile-navigation/profile-navigation.component';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { ExpandMenuDirective } from './directives/expand-menu.directive';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DogHelperComponent } from './components/dog-helper/dog-helper.component';

import {InputTextComponent} from '../custom-components/input-text/input-text.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, SideNavComponent, ExpandMenuDirective, DropdownComponent, DogHelperComponent,
    InputTextComponent
    //  ProfileNavigationComponent
  ],
  imports: [
    MatInputModule,
    MatFormFieldModule,
    CommonModule,
    AngularModule,
    MatIconModule
  ],
  exports: [
    InputTextComponent,
    DogHelperComponent,
    DropdownComponent,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    SideNavComponent,
    HeaderComponent,
    FooterComponent,
    AngularModule
  ]
})
export class SharedModule { }
