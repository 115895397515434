import { Component, OnInit } from '@angular/core';

import { TransactionService, Transaction, QrCodeService } from '@services';
import { Router } from '@angular/router';


const test = {
  date: Date.now(),
  method: 'BTC',
  amount: 0.123,
  CDS_tokens: 1,
  status: 'paid',
  view: true
};


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  transactions: Transaction[] = [];
  constructor(
    private transactionsService: TransactionService,
    private qrService: QrCodeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.transactionsService.transactions.subscribe(res => {
      this.transactions.push(res);
    });
  }
  getMarkColor = (status: string) => {
    switch (status) {
      case 'paid':
        return 'green';
      case 'timeout':
        return 'red';
      default:
        return '#cccc00';

    }

  }
  getTooltiText = (status: string) => {
    switch (status) {
      case 'paid':
        return 'Already paid';
      case 'timeout':
        return 'No payment made after 12 hours';
      default:
        return 'In process';

    }
  }
  navigate = (transaction: Transaction) => {
    if (transaction.status === 'process') {
      this.qrService.setPayment(transaction);
      this.router.navigateByUrl('payment-detail');
      console.log(transaction);
    }
  }

}
