import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-race-track',
  templateUrl: './race-track.component.html',
  styleUrls: ['./race-track.component.scss']
})
export class RaceTrackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
