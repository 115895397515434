import { Component, OnInit } from '@angular/core';
import { CurrencyService, Currency, FromCryptoToUSD, FromUSDtoCrypto } from '../../services/currency.service';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { QrCodeService } from '../../services/qr-code.service';
import { usedCurrencies, transactionComission, coinDogBasePrice } from '../../config';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { opacityAnimation } from '@animations';

// import {} from ''
// https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC&tsyms=USD,EUR
interface Input {
  [key: string]: AbstractControl;
}
interface CurrencyInput {
  controlName: string;
  control: { [key: string]: AbstractControl };
  logo: string;
  currId: string;
}
@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
  animations: [opacityAnimation]
})
export class DepositComponent implements OnInit {
  dogHelperOpen = false;
  coinDogBonus: number;
  coinDogPrice = coinDogBasePrice;
  coinDogInput = new FormControl('');
  subscription: { [key: string]: Subscription } = {};
  inputInFocus: string;

  currencyPaymentList: CurrencyInput[];

  keys = Object.keys;
  lastCalculated: string;


  cryptoForm = new FormGroup({});

  paymentList: Currency;
  currencyList: any[];
  prices: any;
  fromCryptoToUSD: FromCryptoToUSD;
  fromUSDtoCrypto: FromUSDtoCrypto;
  constructor(
    private currencyService: CurrencyService,
    private router: Router,
    private qrService: QrCodeService,
    private http: HttpClient
  ) {
    this.currencyService.fromCryptoToUSD.subscribe(res => {
      this.fromCryptoToUSD = res;
      console.log(res);

    });
    this.currencyService.fromUSDtoCrypto.subscribe(res => {
      this.fromUSDtoCrypto = res;
      console.log(res);

    });


    this.currencyList = usedCurrencies.map(curr => {
      this.cryptoForm.addControl(curr, new FormControl(''));

      return { currId: curr, currencyLogoImg: `assets/img/new/crypto_logo/${curr}.svg` };
    });
    currencyService.tickerData.subscribe(res => {
      this.paymentList = res;
    });


  }


  calculateCrypto = (currency: string, value: number) => {
    return (this.fromUSDtoCrypto.USD[currency] * this.coinDogPrice.value * value * 1.03).toFixed(6);

  }
  calculateCoinDogs = (currency: string, value: number) => {
    return (this.fromCryptoToUSD[currency].USD * value / this.coinDogPrice.value / 1.03).toFixed(2);
  }
  navigate(e: Event, id: string) {
    e.preventDefault();
    if (this.coinDogInput.value) {
      console.log(id, this.cryptoForm.value[id], this.coinDogInput.value);

      this.qrService.updatePayment(id, this.cryptoForm.value[id], this.coinDogInput.value);
    }

    this.router.navigateByUrl('payment-detail');
  }
  unsubscribe(id) {
    this.subscription[id].unsubscribe();
  }
  test(id) {
    this.inputInFocus = id;

    this.subscription[id] = this.cryptoForm.controls[id].valueChanges.subscribe(res => {
      this.coinDogInput.setValue(this.calculateCoinDogs(this.inputInFocus, res));
    });




  }
  ngOnInit() {
    setTimeout(() => {
      this.dogHelperOpen = true;
    }, 1000);
    console.log(this.currencyService.getCoinDogAmount());
    if (this.currencyService.getCoinDogAmount() > 0) {
      this.coinDogInput.setValue(this.currencyService.getCoinDogAmount());
      for (const key in this.cryptoForm.controls) {
        if (key !== this.inputInFocus) {
          this.cryptoForm.controls[key].setValue(this.calculateCrypto(key, this.currencyService.getCoinDogAmount()));
        }
      }
    }

    this.coinDogInput.valueChanges.subscribe(res => {
      this.currencyService.updateCoinDogAmount(res);
      for (const key in this.cryptoForm.controls) {
        if (key !== this.inputInFocus) {
          this.cryptoForm.controls[key].setValue(this.calculateCrypto(key, res));
        }
      }
    });



  }

}
