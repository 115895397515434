import { trigger, transition, style, animate } from '@angular/animations';

export const dropDownAnimation = trigger('dropDown', [
    transition(':enter', [
        style({ height: '0', overflow: 'hidden' }),
        animate('200ms ease-in', style({ height: '*' }))
    ]),
    transition(':leave', [
        style({ height: '*', overflow: 'hidden' }),
        animate('200ms ease-in', style({ height: '0' }))
    ])
]);
