import { Injectable } from '@angular/core';

import { config } from '../config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ReplaySubject } from 'rxjs';

export type Device = 'mobile' | 'tablet' | 'fullscreen';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  public device = new ReplaySubject<Device>();
  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    breakpointObserver.observe([
      config.mobileWidth, config.tabletWidth
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[config.mobileWidth]) {
          // console.log('mobile');

          this.device.next('mobile');
          // this.store.set(Section.device, 'mobile');
          return;
        }
        if (result.breakpoints[config.tabletWidth]) {
          this.device.next('tablet');
          // console.log('tablet');
          // this.store.set(Section.device, 'tablet');
          return;
        }
      } else {
        // console.log('BIG');
        this.device.next('fullscreen');
        // this.store.set(Section.device, 'fullscreen');
      }
    });
  }
}
