export const config = {
    mobileWidth: '(max-width: 400px)',
    tabletWidth: '(max-width: 680px)'
};
export const currencyWallet = {
    BTC: '1C3a6AuzNniYdkuTEBTeiXVBky8F9DbXYb',
    BCH: 'qqzvx734m5nya3um70szzl6ufx5shr5n6q705f70tr',
    ETH: '0x692aBE423A9e2966CC1719565a314B809bdA2ABf',
    LTC: 'LhuMp7uw3NL6xEi77Y2EZnvFmnJTigyJkL',
    DASH: 'Xq3FRvVQ8RMi6MjaFe1oM3Vhb96E5iUNdV',
    ETC: '0x68d9813FDe506F891867D30ad2f487bbBf3Cd0f2'
};
// export const usedCurrencies = ['BTC', 'LTC', 'ETH', 'XMR', 'BCH', 'DOGE', 'ZEC', 'DASH'
// ];
// export const usedCurrencies = ['ZEC', 'BTC', 'LTC', 'ETH', 'BCH', 'DASH'];
// export const usedCurrencies = ['BTC', 'BCH', 'ETH', 'LTC', 'DASH', 'ETC'];
export const usedCurrencies = ['BTC', 'ETH', 'LTC', 'BCH', 'DASH'];
export const currencyNames = {
    // ZEC: 'Z-cash',
    // BTC: 'bitcoin',
    // LTC: 'litecoin',
    // ETH: 'ethirium',
    // BCH: 'B-cash',
    // DASH: 'Dash'
    BTC: 'Bitcoin',
    BCH: 'BitcoinCash',
    ETH: 'Ethereum',
    LTC: 'Litecoin',
    DASH: 'Dash',
    // ETC: 'Ethereum Classic'
};
export const transactionComission = {
    percent: 3
};
export const mainCurrency = 'USD';
export const coinDogBasePrice: { value: number, currency: string } = { currency: mainCurrency, value: 1 };
