import { Injectable } from '@angular/core';
import { currencyWallet } from '../config';
import { ReplaySubject } from 'rxjs';
export interface Payment {
  id: string;
  value: number;
  qrImg: string;
  CDS: number;
  wallet: string;
  date?: number;
  status?: string;
}
@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  public paymentDetails = new ReplaySubject<Payment>();
  constructor() { }
  public generateQrImage = (currency: string, amount?: number, gas?: number): string => {
    const qrUrl = 'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=';
    switch (currency) {
      case 'BTC':
        if (!amount) {
          return `${qrUrl}bitcoin:${currencyWallet.BTC}`;
        }
        return `${qrUrl}bitcoin:${currencyWallet.BTC}?amount=${amount}`;
      case 'LTC':
        if (!amount) {
          return `${qrUrl}litecoin:${currencyWallet.LTC}`;
        }
        return `${qrUrl}litecoin:${currencyWallet.LTC}?amount=${amount}`;
      case 'ETH':
        if (!amount) {
          return `${qrUrl}ethereum:${currencyWallet.ETH}`;
        }
        return `${qrUrl}ethereum:${currencyWallet.ETH}?amount=${amount}&gas=${gas}`;
      // case 'XMR':
      //   if (!amount) {
      //     return `${qrUrl}monero:${currencyWallet.XMR}`;
      //   }
      //   return `${qrUrl}monero:${currencyWallet.XMR}?tx_amount=${amount}`;
      case 'BCH':
        if (!amount) {
          return `${qrUrl}bitcoincash:${currencyWallet.BCH}`;
        }
        return `${qrUrl}bitcoincash:${currencyWallet.BCH}?amount=${amount}`;
      case 'DASH':
        if (!amount) {
          return `${qrUrl}dash:${currencyWallet.DASH}`;
        }
        return `${qrUrl}dash:${currencyWallet.DASH}?amount=${amount}`;
      case 'ETC':
        if (!amount) {
          return `${qrUrl}ethereum:${currencyWallet.ETC}`;
        }
        return `${qrUrl}ethereum:${currencyWallet.ETC}?amount=${amount}`;
      // case 'DOGE':
      //   if (!amount) {
      //     return `${qrUrl}doge:${currencyWallet.DOGE}`;
      //   }
      //   return `${qrUrl}doge:${currencyWallet.DOGE}?amount=${amount}`;
      // case 'ZEC':
      //   if (!amount) {
      //     return `${qrUrl}zcash:${currencyWallet.ZEC}`;
      //   }
      //   return `${qrUrl}zcash:${currencyWallet.ZEC}?amount=${amount}`;
      default:
        return 'https://fivera.net/wp-content/uploads/2014/03/error_z0my4n.png';
    }
  }
  public updatePayment = (id: string, value: number, CDS: number) => {
    const payment: Payment = { id, value, qrImg: this.generateQrImage(id, value), CDS, wallet: `${currencyWallet[id]}` };
    this.paymentDetails.next(payment);
  }
  public setPayment = (payment: Payment) => {
    this.paymentDetails.next(payment);
  }
}
