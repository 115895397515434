import { Component, OnInit } from '@angular/core';
import { Store, Section } from './store';
import * as jwtDecode from 'jwt-decode';
import { RouterOutlet } from '@angular/router';
import { sideNavAnimation } from '@animations';


import { DeviceService } from '@services';

import { CurrencyService } from './services/currency.service';

import { ViewOptionService, ViewOptions } from './services/view-option.service';

import { slideInAnimation } from './animation';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    sideNavAnimation,
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit {
  viewOptions: ViewOptions;
  device: string;
  text = new Array(10).fill('Just bought 3 ETH in Irland');
  title = 'CoinDogs';
  constructor(
    private viewOptionService: ViewOptionService,
    private deviceService: DeviceService,
    private currency: CurrencyService,
    private store: Store,
  ) {
    viewOptionService.options.subscribe(res => {
      this.viewOptions = res;
    });

    this.initUser();
  }

  prepareRoute(outlet: RouterOutlet) {

    if (!outlet.activatedRouteData.test) {
      return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
    console.log('NO ROUTE');
    return;
  }
  initUser() {
    const token = localStorage.getItem('cabinetCurrentUser');
    if (token) {
      const userDecoded = jwtDecode(token);
      this.store.set(Section.user, userDecoded);
    }
  }

  ngOnInit() {
    this.deviceService.device.subscribe(res => {
      this.device = res;
      // console.log(res);
    });
    let count = 0;
    this.currency.tickerData.subscribe(res => {
      count++;
      // console.log('HERE', count);

    });

  }
}
