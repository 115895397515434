import { trigger, transition, style, animateChild, animate, group, query } from '@angular/animations';

export const sideNavAnimation =
    trigger('sideNav', [
        transition(':enter', [
            style({
                right: '-100%',
                opacity: 0,
                // transform: 'scale(0)',
                // width: '0vw'
            }),
            animate('0.5s linear', style({
                right: '0%',
                opacity: 1,
                // width: '50vw',
                // transform: 'scale(1)'
            }))
        ]),
        transition(':leave', [
            style({
                right: '0%',
                opacity: 1,
                // width: '50vw',
                // transform: 'scale(1)'
            }),
            animate('0.5s linear', style({
                right: '-100%',
                opacity: 0,
                // width: '0vw',
                // transform: 'scale(0)'
            }))
        ])
    ]);
