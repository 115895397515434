import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ActionComponent } from '../app/pages/action/action.component';
import { MarketComponent } from '../app/pages/market/market.component';
import { BountyHuntComponent } from '../app/pages/bounty-hunt/bounty-hunt.component';
import { FAQComponent } from '../app/pages/faq/faq.component';
import { DepositComponent } from '../app/pages/deposit/deposit.component';
import { PaymentDetailComponent } from '../app/pages/payment-detail/payment-detail.component';
import { DashboardComponent } from '../app/pages/dashboard/dashboard.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { ERC20Component } from './pages/erc20/erc20.component';

const routes: Routes = [
  {
    path: 'erc20',
    component: ERC20Component,
    data: { animation: 'ERC20' }
  },
  {
    path: 'transactions',
    component: TransactionsComponent
  },
  {
    path: 'profile',
    loadChildren: './pages/personal-data/personal-data.module#PersonalDataModule',
    data: { animation: 'Profile' }
  },
  {
    path: 'support',
    loadChildren: './pages/support/support.module#SupportModule',
    data: { animation: 'Support' }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { animation: 'Dashboard' }
  },
  {
    path: 'payment-detail',
    component: PaymentDetailComponent,
    data: { animation: 'PaymentDetail' }
  },
  {
    path: 'deposit',
    component: DepositComponent,
    data: { animation: 'Deposit' }
  },
  {
    path: 'auth',
    loadChildren: './pages/auth/auth.module#AuthModule',
    data: { animation: 'AuthPage' }
  },
  {
    path: 'action',
    component: ActionComponent,
    data: { animation: 'ActionPage' }
  },
  {
    path: 'market',
    component: MarketComponent,
    data: { animation: 'MarketPage' }
  },
  {
    path: 'bounty',
    component: BountyHuntComponent,
    data: { animation: 'BountyPage' }
  },
  // {
  //   path: 'faq',
  //   component: FAQComponent,
  //   data: { animation: 'FAQPage' }
  // },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: 'dashboard',
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
