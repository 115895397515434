// tslint:disable-next-line:max-line-length
import { Directive, HostBinding, HostListener, ElementRef, ViewChild, ViewChildren, Renderer2, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appExpandMenu]'
})
export class ExpandMenuDirective {
  constructor(
    private el: ElementRef, private renderer: Renderer2, private container: ViewContainerRef) {
    // this.ChangeBgColor('red');
  }
  @ViewChildren('header') header: ElementRef;
  @HostBinding('class.active') isOpen = false;
  @HostListener('click', ['$event']) toggleOpen(event: any) {
    // console.log(this.template);

    const header: HTMLElement = this.el.nativeElement.getElementsByTagName('header')[0];
    const main: HTMLElement = this.el.nativeElement.getElementsByTagName('main')[0];
    header.classList.add('active');
    main.classList.add('active');
    // console.log(header);
    // console.log(main);
    // console.log(this.el);
    // this.el.nativeElement.
    // const target = event.target as HTMLElement;
    // const parent = event.target.parentElement as HTMLElement;
    // console.log(target);
    // console.log(parent);


    // if (target.classList.contains('dropdown-title') || parent.classList.contains('dropdown-title')) {
    //   this.isOpen = !this.isOpen;
    // }
  }
}
