import { Component, OnInit } from '@angular/core';
import { opacityAnimation } from '@animations';

@Component({
  selector: 'app-dog-helper',
  templateUrl: './dog-helper.component.html',
  styleUrls: ['./dog-helper.component.scss'],
  animations: [
    opacityAnimation
  ]
})
export class DogHelperComponent implements OnInit {
  open = false;
  showTip = false;
  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.showTip = true;
    }, 2000);

  }
  toggle(value?: boolean) {
    console.log('HERE');
    if (value) {
      this.open = value;
      this.showTip = false;
    }

  }
}
