import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import {MatCheckboxModule} from '@angular/material/checkbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Store } from './store';



import { SharedModule } from './shared/shared.module';
import { RaceTrackComponent } from './pages/race-track/race-track.component';
import { ActionComponent } from './pages/action/action.component';
import { MarketComponent } from './pages/market/market.component';
import { BountyHuntComponent } from './pages/bounty-hunt/bounty-hunt.component';
import { FAQComponent } from './pages/faq/faq.component';
import { DepositComponent } from './pages/deposit/deposit.component';
import { PaymentDetailComponent } from './pages/payment-detail/payment-detail.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { CardProfileComponent } from './pages/dashboard/card-profile/card-profile.component';
import { CardTokenComponent } from './pages/dashboard/card-token/card-token.component';
import { CardBalanceComponent } from './pages/dashboard/card-balance/card-balance.component';
import { CardTestProductComponent } from './pages/dashboard/card-test-product/card-test-product.component';
import { CardTokenListComponent } from './pages/dashboard/card-token-list/card-token-list.component';
import { CardTokenCalculatorComponent } from './pages/dashboard/card-token-calculator/card-token-calculator.component';
import { InputTextComponent } from './custom-components/input-text/input-text.component';

import { TransactionsComponent } from './pages/transactions/transactions.component';
import { ERC20Component } from './pages/erc20/erc20.component';
// import {InputTextComponent} from './custom-components/input-text/input-text.component';



@NgModule({
  declarations: [
    AppComponent,
    RaceTrackComponent,
    ActionComponent,
    MarketComponent,
    BountyHuntComponent,
    FAQComponent,
    DepositComponent,
    PaymentDetailComponent,
    DashboardComponent,
    CardProfileComponent,
    CardTokenComponent,
    CardBalanceComponent,
    CardTestProductComponent,
    CardTokenListComponent,
    CardTokenCalculatorComponent,
    // InputTextComponent,
    TransactionsComponent,
    ERC20Component

  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [Store],
  bootstrap: [AppComponent]
})
export class AppModule { }
