import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pluck, filter } from 'rxjs/operators/';
import { Country, ViewOptions } from '../models';
import { CurrencyPair } from '../_services';

export interface State {
  device: 'mobile' | 'tablet' | 'fullscreen';
  sideNavOpen: boolean;
  viewOptions: ViewOptions;
  user: any;
  countriesList: Country;
  currencyPairSelected: CurrencyPair;
  favoritePairs: string[];
  bestPrice: {
    buy: number;
    sell: number;
  };
  [key: string]: any;
}
const options = {
  myTradingHistory: true,
  tradingHistory: true,
  buyOrders: true,
  sellOrders: true,
  favoriteCoins: true,
  hotCoins: true,
  aboveTheMenu: true,
  overChart: false,

};
// default state
const initState: State = {
  device: 'fullscreen',
  sideNavOpen: false,
  viewOptions: JSON.parse(localStorage.getItem('viewOptions')) || options,
  user: undefined,
  countriesList: undefined,
  currencyPairSelected: undefined,
  favoritePairs: JSON.parse(localStorage.getItem('favoritePairs')) || [],
  bestPrice: { buy: 0, sell: 0 }
};

// create list of sections store which we can select
export enum Section {
  device = 'device',
  sideNavOpen = 'sideNavOpen',
  user = 'user',
  countriesList = 'countriesList',
  currencyPairSelected = 'currencyPairSelected',
  favoritePairs = 'favoritePairs',
  viewOptions = 'viewOptions',
  bestPrice = 'bestPrice'
}

export class Store {
  private subject = new BehaviorSubject<any>(initState);
  private store = this.subject.asObservable().pipe(distinctUntilChanged());

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  set(name: string, newState: any) {
    this.subject.next({ ...this.value, [name]: newState });
    // console.log('STORE UPDATE:::', this.value);
  }
}
